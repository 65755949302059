import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Table, Button, Spinner, Form, Modal } from 'react-bootstrap';
import './OrderDetails.css';

const OrderDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [cart, setCart] = useState(null);
  const [originalCart, setOriginalCart] = useState(null);
  const [editedItems, setEditedItems] = useState({});
  const [shippingMethod, setShippingMethod] = useState('');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmPickupModal, setShowConfirmPickupModal] = useState(false);
  const [showConfirmAndPayLaterModal, setShowConfirmSendAndPayLaterModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.count("fetchData called");

        // Fetch order details
        const orderResponse = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`);
        const orderData = orderResponse.data;

        // Fetch cart if cart_id is available
        let cartData = null;
        if (orderData.cart_id) {
          const cartResponse = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/cart/${orderData.cart_id}`);
          cartData = cartResponse.data;
        }

        // Fetch shipping method
        let shippingMethodData = '';
        const shippingResponse = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/consignments`);
        if (shippingResponse.data.shipping && shippingResponse.data.shipping.length > 0) {
          shippingMethodData = shippingResponse.data.shipping[0].shipping_method;
        }

        // Update all states at once
        setOrder(orderData);
        setCart(cartData);
        setOriginalCart(cartData);
        setShippingMethod(shippingMethodData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const canConfirmPickup = useMemo(() => {
    return (
      order &&
      (order.payment_method.toLowerCase().includes('pago contra entrega') || 
      order.payment_method.toLowerCase().includes('efectivo contra entrega')) &&
      shippingMethod.toLowerCase().includes('recoger') &&
      location.state?.from === 'preparando pedido' &&
      order.status_id === 9
    );
  }, [order, location.state]);
  
  const canConfirmSendAndPayLater = useMemo(() => {
    return (
      order &&
      (order.payment_method.toLowerCase().includes('pago contra entrega') || 
      order.payment_method.toLowerCase().includes('efectivo contra entrega')) &&
      shippingMethod === 'Envío a domicilio' &&
      location.state?.from === 'preparando pedido' &&
      order.status_id === 9
    );
  }, [order, location.state]);

  const canCaptureFunds = useMemo(() => {
    return (
      order &&
      location.state?.from === 'preparando pedido' &&
      //hippingMethod === 'Envío a domicilio' && Quitamos la condición de pago con tarjeta sólo en envío a domicilio.
      (!order.staff_notes || !order.staff_notes.includes('Stripe Transaction ID')) &&
      !(
        order.payment_method.toLowerCase().includes('pago contra entrega') || 
        order.payment_method.toLowerCase().includes('efectivo contra entrega')
      ) &&      
      order.status_id !== 11
    );
  }, [order, location.state]);

  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`);
      setOrder(response.data);
  
      if (response.data.cart_id && !cart) { // Solo llama a fetchCart si no se ha cargado el carrito aún
        await fetchCart(response.data.cart_id);
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchShippingMethod = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/consignments`);
      if (response.data.shipping && response.data.shipping.length > 0) {
        setShippingMethod(response.data.shipping[0].shipping_method);
      }

    } catch (error) {
      console.error('Error fetching shipping method:', error);
    }
  };

  const fetchCart = async (cartId) => {
    if (!originalCart) { // Evita cargar el carrito varias veces
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/cart/${cartId}`);
        setCart(response.data);
        setOriginalCart(response.data);
      } catch (error) {
        console.error('Error fetching cart details:', error);
      }
    }
  };

  const handleQuantityChange = (productId, value) => {
    const item = cart.cartItems.find(item => item.product_id === productId);
  
    if (item) {
      const isKg = item.custom_fields && item.custom_fields.tipo === "Kg";
      const newValue = parseFloat(value) || 0;
  
      let updatedItem;
  
      if (isKg) {
        const totalPrice = newValue * item.non_sale_price;
        updatedItem = {
          ...item,
          selected_weight: newValue,
          price: parseFloat(totalPrice.toFixed(2)), // Asegura 2 decimales
        };
      } else {
        const totalPrice = newValue * item.non_sale_price;
        updatedItem = {
          ...item,
          quantity: Math.round(newValue), // Redondea para piezas
          price: parseFloat(totalPrice.toFixed(2)),
        };
      }
  
      setEditedItems(prevEditedItems => ({
        ...prevEditedItems,
        [productId]: true,
      }));
  
      setCart(prevCart => {
        const updatedCartItems = prevCart.cartItems.map(cartItem =>
          cartItem.product_id === productId ? updatedItem : cartItem
        );
  
        const newTotal = updatedCartItems.reduce((acc, item) => acc + (item.price || 0), 0);
  
        return {
          ...prevCart,
          cartItems: updatedCartItems,
          total: parseFloat(newTotal.toFixed(2)), // Subtotal con 2 decimales
        };
      });
    }
  };  

  const handleSaveChanges = async () => {
      setUpdating(true);

      try {
          // Filtro para obtener los productos que fueron editados
          const editedItemsArray = cart.cartItems.filter(item => editedItems[item.product_id]);

          // Función para actualizar un producto
          const updateProduct = async (item) => {
              const isKg = item.custom_fields && item.custom_fields.tipo === 'Kg';

              const weightOrQuantity = isKg 
                  ? parseFloat(item.selected_weight) 
                  : parseFloat(item.quantity);

              const pesokgField = item.custom_fields && item.custom_fields.pesokg 
                  ? parseFloat(item.custom_fields.pesokg)
                  : null;

              const finalPrice = pesokgField 
                  ? parseFloat(item.non_sale_price) * weightOrQuantity 
                  : item.non_sale_price * weightOrQuantity;

              const customName = isKg
                  ? (item.custom_name.toLowerCase().includes("orden de") && item.custom_name.toLowerCase().includes("kg"))
                      ? `1 Orden de ${weightOrQuantity.toFixed(3)} kg de ${item.name}`
                      : `${item.quantity} Pieza(s) de ${item.name} por un total de ${weightOrQuantity.toFixed(3)} kg`
                  : item.custom_name;

              const updatedProduct = {
                  ...item,
                  ...(isKg ? { selected_weight: weightOrQuantity.toFixed(3) } : { quantity: weightOrQuantity }),
                  price_inc_tax: parseFloat(finalPrice).toFixed(2),
                  price_ex_tax: parseFloat(finalPrice).toFixed(2),
                  custom_name: customName
              };

              // Enviamos la actualización del producto editado
              await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/products/${item.product_id}`, updatedProduct);
          };

          // Realizamos las actualizaciones de productos de manera secuencial
          for (const item of editedItemsArray) {
              await updateProduct(item);
          }

          // Obtener el total de la orden incluso si no todos los productos fueron editados
          const totalOrderPrice = calculateCartTotal();

          // Enviar el total calculado al endpoint /total-order
          await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/total-order/${id}`, {
              totalAmount: totalOrderPrice.toFixed(2) // Enviamos el total calculado
          });
          console.log('sent');

          // Refrescamos los detalles de la orden
          await fetchOrderDetails();
          setEditedItems({});
          setEditing(false);
      } catch (error) {
          console.error('Error updating order products or total:', error);
      } finally {
          setUpdating(false);
      }
  };

  const handleEditToggle = () => {
    setEditing(!editing);
  };

  const handleCancelEdit = () => {
    setCart(originalCart);
    setEditedItems({});
    setEditing(false);
  };

  const handleCaptureFunds = async () => {
    setIsProcessing(true);
    let response;
    try {
      response = await axios.post(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}/capture-funds`);
      setIsProcessing(false);
      setShowConfirmModal(false);
      await fetchOrderDetails();

      if (response && response.status === 200 && shippingMethod.includes("Recoger en Sucursal")) {
        await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {
          status_id: 8
        });
        await fetchOrderDetails();
        setEditing(false);
      }
    } catch (error) {
      console.error('Error capturing funds or updating order status:', error);
      setIsProcessing(false);
    }
  };

  const handleConfirmPickup = async () => {
    setIsProcessing(true);
    let response;
    try {
      response = await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {
        status_id: 12
      });
      setIsProcessing(false);
      setShowConfirmPickupModal(false);
      await fetchOrderDetails();
    } catch (error) {
      console.error('Error updating order status:', error);
      setIsProcessing(false);
    } finally {
      if (response && response.status === 200) {
        setEditing(false);
      }
    }
  };

  const handleConfirmSendAndPayLater = async () => {
    setIsProcessing(true);
    let response;
    try {
      response = await axios.put(`${process.env.REACT_APP_BACKEND_NODE_URL}/api/orders/${id}`, {
        status_id: 2
      });
      setIsProcessing(false);
      setShowConfirmSendAndPayLaterModal(false);
      await fetchOrderDetails();
    } catch (error) {
      console.error('Error updating order status:', error);
      setIsProcessing(false);
    } finally {
      if (response && response.status === 200) {
        setEditing(false);
      }
    }
  };

  const extractScheduledDate = (message) => {
    const regex = /Fecha programada: (\d{2}\/\d{2}\/\d{4})/;
    const match = message.match(regex);
    return match ? match[1] : 'N/A';
  };

  const removeScheduledDate = (message) => {
    const cleanedMessage = message
      .replace(/Fecha programada: \d{2}\/\d{2}\/\d{4}/, '')
      .replace(/^\|? Comentarios del pedido:/, '')
      .trim();
    return cleanedMessage;
  };

  const formatCurrency = (amount, shouldRound = true) => {
    if (shouldRound) {
      return `$${parseFloat(amount).toFixed(2)}`;
    }
    return `$${amount}`; // Devuelve sin redondear
  };

  const formatCurrencyWithoutSymbol = (amount, shouldRound = true) => {
    if (shouldRound) {
      return parseFloat(amount).toFixed(2);
    }
    return amount.toString(); // Devuelve el número sin redondear como cadena
  };

  const handleBackClick = () => {
      if (location.state && location.state.from) {
          const cleanedPath = location.state.from.replace(/\s+/g, '-').toLowerCase(); // Reemplazar espacios por guiones y convertir a minúsculas
          navigate(`/${cleanedPath}`);
      } else {
          navigate('/orders');  // Navegar a la lista de órdenes o una ruta predeterminada
      }
  };

  const canEdit = () => {
    return (
        order &&
        location.state?.from === 'preparando pedido' &&
        (!order.staff_notes || !order.staff_notes.includes('Stripe Transaction ID')) &&
        order.status_id === 9
    );
  };

  const getOrderStatus = () => {
    if (order.status === 'Awaiting Fulfillment') {
      return 'En espera de entrega';
    } else if (order.status === 'Awaiting Payment') {
      return 'Orden creada y En espera de cobro';
    } else {
      return order.status;
    }
  };

  const calculateCartTotal = () => {
    return cart.cartItems.reduce((acc, item) => acc + (item.price || 0), 0);
  };

  const handleDownload = () => {
    let content = `${order.billing_address.last_name}\n`;
    cart.cartItems.forEach(item => {
      const product = order.products.find(p => p.product_id === item.product_id);
      const sku = product ? product.sku : 'SKU no encontrado';
      content += `${sku}\t${formatCurrencyWithoutSymbol(item.non_sale_price)}\t${item.selected_weight || item.quantity}\t${item.comment || ''}\n`;
    });

    content += `\nAPP-ORDEN: ${order.id}\n`;
    content += `Método de Pago: ${order.payment_method}\n`;
    content += `Fecha solicitada de entrega: ${extractScheduledDate(order.customer_message)}\n`;
    content += `Método de envío: ${shippingMethod}\n`;
    content += `Comentario de pedido: ${removeScheduledDate(order.customer_message)}\n`;
    content += `${order.billing_address.first_name}`;

    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `orden_${order.id}.txt`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <div className="container mt-4">
      {(loading || updating) && (
        <div className="overlay">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Detalle de la orden {order ? <>{order.id}<br /><span style={{ fontSize: '0.6em' }}>{order.billing_address.first_name} {order.billing_address.last_name}</span></> : ''}</h1>
        <div>
          <Button variant="warning" onClick={handleDownload} style={{ marginRight: '12px' }}>Descargar</Button>
          <Button variant="secondary" onClick={handleBackClick}>Regresar</Button>
        </div>
      </div>
      <div className="table-container top-table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Nombre del producto</th>
              <th>Precio Unidad</th>
              <th>Cantidad</th>
              <th>Monto Total</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody>
          {cart && cart.cartItems.map(item => {
            const product = order.products.find(p => p.product_id === item.product_id);
            const sku = product ? product.sku : 'SKU no encontrado';
            const pesokgField = product?.custom_fields?.find(field => field.name === 'pesokg');
            const tipoField = product?.custom_fields?.find(field => field.name === 'tipo' && field.value === 'Kg');
            const selectedWeight = item.selected_weight !== undefined && item.selected_weight !== '' 
                  ? item.selected_weight 
                  : (pesokgField?.value || item.quantity || 0);

            // Definir isKg antes de usarlo
            const isKg = tipoField && tipoField.value === 'Kg'; // Validar si es por Kg

            // Si es por peso, el precio por unidad es el precio por kg y el total se multiplica por el peso seleccionado
            const pricePerUnit = item.non_sale_price;
            const totalPrice = isKg 
              ? parseFloat(item.non_sale_price) * parseFloat(selectedWeight) // Multiplica el precio por el peso en kg
              : pricePerUnit * item.quantity; // Si no es por peso, multiplica por la cantidad de piezas

            return (
              <tr key={item.id}>
                <td>{sku}</td>
                <td>{item.custom_name}</td>

                {/* Muestra el precio por unidad */}
                <td>{formatCurrency(pricePerUnit)}</td>
                <td>
                  {editing ? (
                    <Form.Control
                      type="number"
                      value={selectedWeight}
                      min="0"
                      step={isKg ? "0.01" : "1"} // Si es Kg permitir decimales, si es Pz solo enteros
                      onChange={(e) => {
                        let value = parseFloat(e.target.value) || 0;
                        
                        // Si no es por Kg (es Pz), redondeamos el valor a entero
                        if (!isKg) {
                          value = Math.round(value);  // Redondear a número entero si es por Pz
                        }
                        
                        handleQuantityChange(item.product_id, value);
                      }}
                    />
                  ) : (
                    selectedWeight
                  )}
                </td>
                <td>{formatCurrency(totalPrice)}</td>
                <td>{item.comment || ''}</td>
              </tr>
            );
          })}
        </tbody>
        </Table>
        <div className="d-flex justify-content-end">
          {canEdit() && (
            <>
              {editing ? (
                <>
                  <Button variant="primary" onClick={handleSaveChanges} className="mt-3" style={{ marginRight: '12px' }}>Guardar</Button>
                  <Button variant="secondary" onClick={handleCancelEdit} className="mt-3 ml-2">Cancelar</Button>
                </>
              ) : (
                <Button variant="primary" onClick={handleEditToggle} className="mt-3">Editar</Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="details-table-container">
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td><strong>Estatus:</strong></td>
              <td>{order ? order.custom_status : ''}</td>
            </tr>
            <tr>
              <td><strong>Método de Pago:</strong></td>
              <td>{order ? order.payment_method : ''}</td>
            </tr>
            <tr>
              <td><strong>Fecha solicitada de entrega:</strong></td>
              <td>{order ? extractScheduledDate(order.customer_message) : ''}</td>
            </tr>
            <tr>
              <td><strong>Método de entrega:</strong></td>
              <td>{shippingMethod}</td>
            </tr>
            {order && order.staff_notes && order.staff_notes.includes('Stripe Transaction ID') && (
              <tr>
                <td><strong>Confirmación de Pago:</strong></td>
                <td>Stripe ID: {order.staff_notes.match(/Stripe Transaction ID: (\w+)/)?.[1] || 'ID no encontrado'}</td>
              </tr>
            )}
            <tr>
              <td><strong>Comentario:</strong></td>
              <td>{order ? removeScheduledDate(order.customer_message) : ''}</td>
            </tr>
            <tr>
              <td><strong>Subtotal:</strong></td>
              <td>{cart && cart.total ? formatCurrency(cart.total) : '$0.00'}</td>
            </tr>
            <tr>
              <td><strong>Descuento:</strong></td>
              <td>{order ? formatCurrency(order.discount_amount) : ''}</td>
            </tr>
            <tr>
              <td><strong>Envío:</strong></td>
              <td>{order ? formatCurrency(order.shipping_cost_inc_tax) : ''}</td>
            </tr>
            <tr>
              <td><strong>Total del carrito:</strong></td>
              <td>{cart ? formatCurrency(calculateCartTotal()) : '$0.00'}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-end">
        {canCaptureFunds && (
          <Button variant="success" onClick={() => setShowConfirmModal(true)} className="mt-3">Realizar Cobro</Button>
        )}
        {canConfirmPickup && (
          <Button variant="primary" onClick={() => setShowConfirmPickupModal(true)} className="mt-3">Confirmar Recolección</Button>
        )}
        {canConfirmSendAndPayLater && (
          <Button variant="primary" onClick={() => setShowConfirmSendAndPayLaterModal(true)} className="mt-3">Confirmar Envío</Button>
        )}
      </div>
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Seguro que quiere realizar cobro?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cerrar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleCaptureFunds}
            disabled={isProcessing}
            style={{ backgroundColor: isProcessing ? '#6c757d' : '#007bff' }}
          >
            {isProcessing ? 'Capturando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmPickupModal} onHide={() => setShowConfirmPickupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Seguro que quiere cambiar el estatus a "Listo y en espera de recolección"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmPickupModal(false)}>
            Cerrar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleConfirmPickup}
            disabled={isProcessing}
            style={{ backgroundColor: isProcessing ? '#6c757d' : '#007bff' }}
          >
            {isProcessing ? 'Procesando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmAndPayLaterModal} onHide={() => setShowConfirmPickupModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Seguro que quiere cambiar el estatus a "En Ruta"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmPickupModal(false)}>
            Cerrar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleConfirmSendAndPayLater}
            disabled={isProcessing}
            style={{ backgroundColor: isProcessing ? '#6c757d' : '#007bff' }}
          >
            {isProcessing ? 'Procesando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrderDetails;
